import { getList, createPDF, renameFile, copyFile, 
  deleteFile, getRechargeData, createOrder, buildPayment, 
  checkPayStatus, createOnlineFile, signFileCount, closeSignFileOrder,
  createWxCode, word2form, getUserSignPosition, saveTemplate, 
  getTemplateList, deleteTemplate, getTemplate, ssFromTemplate } from '@/api/signature'

// initial state
const state = () => ({
  // 模板数据
  templateData: {}
});

// mutations
const mutations = {
  // 设置模板数据
  setTemplateData(state, templateData) {
    state.templateData = templateData;
  },
  // 设置模板名称
  setTemplateName(state, templateName) {
    if (state.templateData) {
      state.templateData.templateName = templateName;
    }
  }
}

// actions
const actions = {
  /**
   * 获取签字列表
   * Added by Jason.Song on 2020/11/17 16:44:31
   */
  getList(context, params) {
    return new Promise((resolve, reject) => {
      getList(params).then(response => {
        const { data } = response
        // console.log(`signature/getList`, data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 生成并下载PDF文件
   * Added by Jason.Song on 2020/11/17 16:47:00
   * @param {*} fileOrderId 
   */
  createPDF(context, fileOrderId) {
    return new Promise((resolve, reject) => {
      createPDF({ fileOrderId }).then(response => {
        const { data } = response
        // console.log(data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 重命名文件
   * Added by Jason.Song on 2020/11/17 16:50:18
   */
  renameFile(context, { fileOrderId, fileName }) {
    return new Promise((resolve, reject) => {
      renameFile({
        fileOrderId,
        fileName
      }).then(response => {
        const { data } = response
        // console.log(data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 复制签名文件
   * Added by Jason.Song on 2020/11/17 16:52:38
   */
  copyFile(context, fileOrderId) {
    return new Promise((resolve, reject) => {
      copyFile({ fileOrderId }).then(response => {
        const { data } = response
        // console.log(data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 删除签名文件
   * Added by Jason.Song on 2020/11/17 16:52:43
   */
  deleteFile(context, fileOrderId) {
    return new Promise((resolve, reject) => {
      deleteFile({ fileOrderId }).then(response => {
        const { data } = response
        // console.log(data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 获取购买次数套餐或开通会员列表
   * Added by Jason.Song on 2020/11/25 11:56:19
   */
  getRechargeData() {
    return new Promise((resolve, reject) => {
      getRechargeData().then(response => {
        const { data } = response
        // console.log(`getRechargeData:`, data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 创建远程签字套餐
   * Added by Jason.Song on 2020/11/25 19:30:05
   */
  createOrder(context, { busId, num, orderNote }) {
    return new Promise((resolve, reject) => {
      // 创建购买订单
      createOrder({
        payMethod: 3,
        busId,
        num,
        appType: 'xhomeMP',
        payType: 'signonline',
        orderNote
    }).then(response => {
        const { data } = response
        // console.log(data);
        // // 构建支付相关信息
        // let options = {
        //   payMethod: 3,
        //   appType: 'xhomeMP',
        //   payType: 'signonline',
        //   openId: data.openid,
        //   orderId: data.orderId,
        //   payAmount: parseFloat(data.orderAmount),
        //   description: data.orderDesc,
        //   payNote: data.orderNote
        // }
        // dispatch('buildPayment', options).then(res => {
        //   resolve(res);
        // }).catch(error => {
        //   reject(error)
        // });
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 构建支付相关信息
   * Added by Jason.Song on 2020/11/25 19:37:11
   */
  buildPayment(context, options) {
    return new Promise((resolve, reject) => {
      buildPayment(options).then(response => {
        const { data } = response
        // console.log(data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 校验支付结果
   * Added by Jason.Song on 2020/11/26 10:28:35
   */
  checkPayStatus({ dispatch }, { batchNo }) {
    return new Promise((resolve, reject) => {
      checkPayStatus({ batchNo }).then(response => {
        const { data } = response
        // console.log(data);
        if(data && data.paySuccess) {
          // 支付成功后重新获取用户信息
          dispatch('user/getInfo', null, { root: true });
        }

        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 保存上传文件至草稿箱
   * Added by Jason.Song on 2020/11/26 13:31:29
   */
  createOnlineFile(context, options) {
    return new Promise((resolve, reject) => {
      createOnlineFile(options).then(response => {
        const { data } = response
        // console.log(data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 签名文件统计数据
   * Added by Jason.Song on 2020/11/27 14:58:33
   */
  signFileCount() {
    return new Promise((resolve, reject) => {
      signFileCount().then(response => {
        const { data } = response
        // console.log(data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 关闭签署文件
   * Added by Jason.Song on 2020/11/27 16:24:24
   */
  closeSignFileOrder(context, fileOrderId) {
    return new Promise((resolve, reject) => {
      closeSignFileOrder({ fileOrderId }).then(response => {
        const { data } = response
        // console.log(data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 创建小程序码
   * Added by Jason.Song on 2020/12/04 17:19:25
   */
  createWxCode(context, { page, scene }) {
    return new Promise((resolve, reject) => {
      createWxCode({ page, scene }).then(response => {
        const { data } = response
        // console.log(data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 将Word文档转成Form表单和图片
   * Added by Jason.Song on 2020/12/08 14:01:40
   */
  word2form({ dispatch, commit }, { filePath, cvtImage }) {
    return new Promise((resolve, reject) => {
      word2form({ filePath, cvtImage }).then(response => {
        const { data } = response;
        // console.log(`word2form:`, data);

        dispatch('getUserSignPosition').then(res => {
          data.userSignPosition = res;
          commit('setTemplateData', data);
          resolve(data);
        }).catch(error => {
          reject(error)
        });

      }).catch(error => {
        reject(error);
      })
    })
  },

  /**
   * 获取用户签名位置
   * Added by Jason.Song on 2020/12/09 14:37:17
   */
  getUserSignPosition() {
    return new Promise((resolve, reject) => {
      getUserSignPosition().then(response => {
        const { data } = response
        // console.log(`getUserSignPosition:`, data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 保存模板
   * Added by Jason.Song on 2020/12/11 16:21:34
   */
  saveTemplate(context, options) {
    return new Promise((resolve, reject) => {
      saveTemplate(options).then(response => {
        const { data } = response
        console.log(`saveTemplate:`, data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 获取模板列表
   * Added by Jason.Song on 2020/12/11 18:19:33
   */
  getTemplateList(context, params) {
    return new Promise((resolve, reject) => {
      getTemplateList(params).then(response => {
        const { data } = response
        console.log(`signature/getTemplateList`, data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 获取模板详情
   * Added by Jason.Song on 2020/12/14 11:18:54
   */
  getTemplate({ dispatch, commit }, params) {
    return new Promise((resolve, reject) => {
      getTemplate(params).then(response => {
        const { data } = response
        console.log(`signature/getTemplate`, data);
        
        dispatch('getUserSignPosition').then(res => {
          data.userSignPosition = res;
          commit('setTemplateData', data);
          resolve(data);
        }).catch(error => {
          reject(error)
        });
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 删除模板
   * Added by Jason.Song on 2020/12/14 10:53:54
   */
  deleteTemplate(context, params) {
    return new Promise((resolve, reject) => {
      deleteTemplate(params).then(response => {
        const { data } = response
        console.log(`signature/deleteTemplate`, data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 通过模板创建签字文件
   * Added by Jason.Song on 2020/12/15 16:50:10
   */
  ssFromTemplate(context, options) {
    return new Promise((resolve, reject) => {
      ssFromTemplate(options).then(response => {
        const { data } = response
        console.log(`signature/ssFromTemplate:`, data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  

}


export default {
  namespaced: true,
  state,
  actions,
  mutations
}