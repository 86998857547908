import Vue from 'vue';
import ElementUI from 'element-ui';
import moment from 'moment'

import '@/styles/index.scss';
import "./assets/css/tailwind.css";
import 'element-ui/lib/theme-chalk/index.css';

import App from './App.vue';
import router from './router';
import store from './store';

import '@/icons'
import '@/permission';

Object.defineProperty(Vue.prototype, '$moment', { value: moment });

Vue.use(ElementUI, { size: 'medium', zIndex: 3000 });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
